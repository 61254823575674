import React from 'react'
import classes from './About.module.css'
import AOS from 'aos'
import 'aos/dist/aos.css'
function About() {
	AOS.init()
	return (
		<section data-aos="fade-up" className={classes.section}>
			<h2 className={classes.h2}>Про SOLID</h2>
			<p>&#8195;Найбільший фітнес-клуб України в Одесі.</p>
			<p>SOLID – це тренажерний зал з кращим обладнанням, crossfit, зал-escape, 
				групові заняття з живою музикою, басейн 25 метрів, площа 5500 м2.
			</p>
			<p>
				&#8195;Замислювалися, чому на Заході кожен третій відвідує тренажерні зали, ходить на заняття аеробікою чи
				займається єдиноборствами? Напевно, ви і самі відвідували фітнес-центри, але так і не змогли 
				змусити себе регулярно ходити туди. Проблема не у вас. Майже всі забувають, що фітнес-клуб — це 
				насамперед саме клуб. Це місце, куди можна прийти і повністю відволіктися від буденних турбот. Насолодитися атмосферою, 
				спілкуванням та гарною живою музикою у виконанні професійного діджея.
			</p>
			<p>
				&#8195;Здивовані? Зрозуміло, адже єдиний справжній фітнес-клуб на сьогоднішній день в Україні лише один,
				і це SOLID.
			</p>
		</section>
	)
}

export default About